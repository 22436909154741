<template>
  <div class="app-main-content">
    <card title="项目文件夹" class="left">
      <template v-slot:title>
        <div class="edp-card-head-left-title" @click="toTop">项目文件夹</div>
      </template>
      <template v-slot:head-end>
        <a-icon v-if="$hasP('FOLDER_CREATE')" type="plus"
                @click="addFolder({oid: '', route_level: -1, prjoid: prj_oid}, false)" />
      </template>
      <edp-tree ref="edpTree" :data="topList" @change="clickTree" row-key="oid" :flag="true"
                @fromSun="getFolderListChildren">
        <template v-slot="{row, open, fun}">
          <div class="tree-row">
            <div class="tree-row-info">
              <img :src='`${appsettings.imgPrefix}folder_open.png`' class="dir" alt="">
              <div class="name">{{ row.folder_name }}</div>
            </div>
            <div class="tree-row-dropdown" @click.stop
                 v-if="$hasP('FOLDER_CREATE') || $hasP('FOLDER_UPDATE') || $hasP('FOLDER_DELETE')">
              <a-dropdown>
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                  <a-icon type="ellipsis" />
                </a>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a v-if="$hasP('FOLDER_CREATE')" href="javascript:;"
                       @click="addFolder(row, false, open, fun)">新增</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a v-if="$hasP('FOLDER_UPDATE')" href="javascript:;" @click="addFolder(row, true)">修改</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a v-if="$hasP('FOLDER_DELETE')" href="javascript:;" @click="delFolder(row)">删除</a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
        </template>
      </edp-tree>
    </card>
    <card class="right" title="文件">
      <template #tools>
        <div style="width: 100%">
          <div class="tools">
            <div class="primary-title">
              全部文件
            </div>
            <div class="line">
              <div class="left">
                <div class="num-message">共{{ arrList.length }}个，已加载{{ arrList.length }}个</div>
              </div>
              <div class="tools-right">
                <div class="item" v-if="$hasP('ATT_UPLOAD')">
                  <a-button type="primary" @click="openUploadModal">
                    <i class="iconfont icon-yunduanshangchuan" style="margin-right: 4px;"></i>
                    上传
                  </a-button>
                </div>
                <div class="item" v-if="$hasP('ATT_MOVE')">
                  <a-button type="primary" @click="changeAttFolder">
                    <a-icon type="drag" />
                    移动
                  </a-button>
                </div>
                <div class="item">
                  <a-button type="primary" @click="delAtts(false)" v-if="$hasP('ATT_DELETE')">
                    <a-icon type="delete" />
                    删除
                  </a-button>
                </div>
                <div class="item">
                  <span class="label">排序</span>
                  <a-select @change="getAttList" placeholder="请选择"
                            v-model="sort">
                    <a-select-option value="0">上传时间倒序</a-select-option>
                    <a-select-option value="1">文件大小倒序</a-select-option>
                  </a-select>
                </div>
                <div class="item">
                  <span class="label">搜索</span>
                  <a-input v-model="search" placeholder="文件名称">
                    <a-icon slot="prefix" type="search" />
                  </a-input>
                </div>
                <a-button class="search" @click="getAttList">查询</a-button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="table">
        <a-table rowKey="oid" :data-source="arrList" :rowSelection="{selectedRowKeys,onChange: changeSelected}"
                 :pagination="false">
          <a-table-column title="文件名称">
            <template v-slot:default="row">
              <div class="line-center">
                <img class="icon" v-if="checkFileType('img',row.file_type)" :src="$toUrl('image.png')">
                <img class="icon" v-else-if="checkFileType('excel',row.file_type)" :src="$toUrl('xls.png')">
                <img class="icon" v-else-if="checkFileType('word',row.file_type)" :src="$toUrl('word.png')">
                <img class="icon" v-else-if="checkFileType('pdf',row.file_type)" :src="$toUrl('pdf.png')">
                <img class="icon" v-else :src="$toUrl('qitafujian.png')">
                <a-tooltip placement="top">
                  <template slot="title">
                    <span>{{ row.description || '暂无' }}</span>
                  </template>
                  {{ row.file_name }}
                </a-tooltip>
              </div>
            </template>
          </a-table-column>
          <a-table-column key="来源" title="来源">
            <template v-slot:default="row">
              {{ row.source_name }}
            </template>
          </a-table-column>
          <a-table-column key="上传者" title="上传者" width="200px">
            <template v-slot:default="row">
              {{ row.uploader_title ? row.uploader_name + '-' + row.uploader_title : row.uploader_name }}
            </template>
          </a-table-column>
          <a-table-column key="文件类型" title="文件类型" width="100px">
            <template v-slot:default="row">
              {{ row.file_type }}
            </template>
          </a-table-column>
          <a-table-column key="文件大小" title="文件大小" width="130px">
            <template v-slot:default="row">
              {{ renderSize(row.file_length - 0) | numberFormat }}KB
            </template>
          </a-table-column>
          <a-table-column key="更新时间" title="更新时间" width="165px">
            <template v-slot:default="row">
              {{ row.update_time }}
            </template>
          </a-table-column>
          <!--          <a-table-column key="文件描述" title="文件描述">-->
          <!--            <template v-slot:default="row">-->
          <!--              {{ row.description }}-->
          <!--            </template>-->
          <!--          </a-table-column>-->
          <a-table-column title="操作" width="220px">
            <template v-slot:default="row">
              <span class="blue" v-if="$hasP('ATT_UPLOAD')" @click="upload(row)">更新</span>
              <span class="blue" v-if="$hasP('ATT_HIS_VIEW')" @click="openHistory(row)">历史</span>
              <span class="blue" v-if="$hasP('ATT_PREVIEW')" @click="$preview(row)">预览</span>
              <a v-if="$hasP('ATT_PREVIEW')" :href="$toUrl(row.file_path)" target="_blank" class="blue">下载</a>
              <span class="blue" v-if="$hasP('ATT_DELETE')" @click="delAtts(true, row)">删除</span>
            </template>
          </a-table-column>
        </a-table>
      </div>
    </card>
    <upload-modal ref="uploadModal" :prj-folder-oid="cur_folder_oid" :prj-oid="prj_oid" source-type="7"
                  @reload="reload"></upload-modal>
    <att-folder-modal ref="attFolderModal" :prj-oid=prj_oid @reload="reload"></att-folder-modal>
    <create-folder-modal ref="createFolderMoadl" @reload="getFolderListTop" @rename="editFolderName"
                         @showChild="showChild"></create-folder-modal>
    <attachment-history-modal :p-preview="$hasP('ATT_PREVIEW')" :p-rollback="$hasP('ATT_HIS_ROLLBACK')"
                              ref="attHistoryModal" @reload="reload"></attachment-history-modal>
  </div>
</template>

<script>
import EdpTree from "@/components/edpTree.vue";
import attFolderModal from "@/components/attFolderModal.vue";
import uploadModal from "@/components/uploadModal.vue";
import { fetch } from '@/utils/request'
import { checkFileType, renderSize } from "@/utils/helpFunc";
import appsettings from '@/utils/appsettings'
import CreateFolderModal from "@/components/createFolderModal.vue";
import attachmentHistoryModal from "@/components/attachmentHistoryModal.vue";

export default {
  components: { CreateFolderModal, EdpTree, attFolderModal, uploadModal, attachmentHistoryModal },
  data() {
    return {
      checkFileType,
      renderSize,
      appsettings,
      topList: [],
      selectedRowKeys: [],
      arrList: [],
      prj_oid: '',
      cur_folder_oid: '',
      sort: '0',
      search: '',
      editFolder: {},
      addOpen: function () {
      }
    }
  },
  created() {
    this.prj_oid = this.$route.query.prj_oid
  },
  mounted() {
    this.getFolderListTop()
    this.getAttList()
  },
  methods: {
    selectActionMenu({ key }) {
      alert("点击了key:" + key)
    },
    changeSelected(list) {
      console.log(list)
      this.selectedRowKeys = list
    },
    getFolderListTop() {
      fetch('post', '/prj/folder/retrieve/toplevel', {
        prjoid: this.prj_oid,
      }).then((res) => {
        if (res.status === 0) {
          this.topList = res.data
          console.log('顶级文件夹', this.topList);
          // if (this.topList.length > 0) {
          //   this.cur_folder_oid = this.topList[0].oid
          // }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getFolderListChildren(row) {
      console.log(row);
      fetch('post', '/prj/folder/retrieve/children', {
        prjoid: this.prj_oid,
        oid: row.oid
      }).then((res) => {
        if (res.status === 0) {
          console.log('子级文件夹', res.data);
          if (res.data.length > 0) {
            this.$set(row, 'children', res.data)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getAttList() {
      fetch('post', '/attachment/retrieve', {
        prjoid: this.prj_oid,
        prj_folderoid: this.cur_folder_oid || '0',
        flag: this.sort,
        keywords: this.search,
        page_size: 99
      }).then((res) => {
        if (res.status === 0) {
          console.log('附件列表', res.data.datalist);
          this.arrList = res.data.datalist
        } else {
          this.$message.error(res.message)
        }
      })
    },
    clickTree(row, ref) {
      console.log(row);
      console.log(ref);
      this.cur_folder_oid = row.oid
      this.getAttList()
    },
    changeAttFolder() {
      if (this.selectedRowKeys.length > 0) {
        let oid = JSON.parse(JSON.stringify(this.selectedRowKeys)).join(',')
        this.$refs.attFolderModal.open(oid)
      } else {
        this.$message.info('请先勾选文件！')
      }
    },
    delAtts(flag, row) {
      if (this.selectedRowKeys.length > 0 || flag) {
        let oid = ''
        if (flag) {
          oid = row.oid
        } else {
          oid = JSON.parse(JSON.stringify(this.selectedRowKeys)).join(',')
        }
        this.$confirm({
          title: '确定要删除吗?',
          onOk: () => {
            return fetch('post', '/attachment/delete', {
              oid
            }).then((res) => {
              if (res.status === 0) {
                this.$message.success('删除成功！')
                this.getAttList()
              } else {
                this.$message.error(res.message)
              }
            })
          },
          onCancel() {
          },
        });

      } else {
        this.$message.info('请先勾选文件！')
      }
    },
    reload() {
      this.selectedRowKeys = []
      this.getAttList()
    },
    openUploadModal() {
      this.$refs.uploadModal.open()
    },
    // 新建文件夹
    addFolder(row, edit, open, fun) {
      console.log(typeof fun);
      console.log(open);
      let data = {
        ...row,
        edit
      }
      if (typeof fun === 'function') this.addOpen = fun
      this.editFolder = Object.assign(row, { open: open })
      console.log(this.editFolder);
      this.$refs.createFolderMoadl.open(data)
    },
    editFolderName(folder_name) {
      this.editFolder.folder_name = folder_name
      console.log(this.editFolder);
    },
    showChild(row) {
      // console.log(this.editFolder);
      // console.log(row);
      if (!this.editFolder.children) this.editFolder.children = []
      this.editFolder.children.push(row)
      if (this.editFolder.open && typeof this.addOpen === 'function') {
        this.addOpen(this.editFolder.oid)
      }
    },
    delFolder(row) {
      console.log(row);
      fetch('post', '/prj/folder/delete', {
        oid: row.oid
      }).then((res) => {
        if (res.status === 0) {
          this.$message.success('删除成功！')
          this.queryTree(this.topList, row.oid)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    queryTree(datas, oid) { // 递归循环查树结构元素
      datas.forEach((item, index) => {
        if (item.oid === oid) {
          // console.log(datas, datas[index]);
          this.$delete(datas, index)
          this.$refs.edpTree.$forceUpdate()
          return;
        }
        if (item.children) {  //存在子节点就递归
          this.queryTree(item.children, oid);
        }
      })
    },
    // 历史
    openHistory(row) {
      this.$refs.attHistoryModal.open(row)
    },
    // 更新
    upload(row) {
      console.log(row.oid);
      this.$refs.uploadModal.open(row)
    },
    toTop() {
      this.cur_folder_oid = ""
      this.getAttList()
    }
  }
}
</script>

<style lang="less" scoped>
.blue {
  color: var(--primary-color);
  cursor: pointer;
  margin-right: 8px;
}

.app-main-content {
  display: flex;

  > .left {
    width: 280px;
    flex-shrink: 0;

    .tree-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      font-size: 16px;
      font-weight: 500;

      .tree-row-info {
        display: flex;
      }

      .tree-row-dropdown {
        padding-right: 5px;
      }

      .dir {
        margin-right: 5px;
        height: 22px;
      }

      .menu-icon .iconfont {
        font-size: 16px;
        font-weight: 500;
      }
    }

    /deep/ .edp-tree-item {
      &.active {
        > .tree-item-row > .tree-row {
          .menu-icon {
            display: block;
          }
        }
      }

      > .tree-item-row {
        .tree-row {
          .name {
            width: 180px;
          }
        }

        .menu-icon {
          display: none;
        }

        &:hover {
          .menu-icon {
            display: block;
          }
        }
      }
    }
  }

  > .right {
    min-width: 1000px;
    overflow: hidden;
    flex: 1;
    margin-left: 10px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;

    .tools {
      padding: 10px 0;
      width: 100%;

      .primary-title {
        display: flex;
        align-items: center;
        font-size: 18px;
        color: #3B3B3B;

        .description {
          font-weight: 400;
          font-size: 14px;
          color: #4D4D4D;
        }
      }

      .line {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    > .head {
      flex-shrink: 0;
      padding: 10px 20px;
      border-bottom: 1px solid #EDEDED;

      .title {
        .subtitle {
          font-size: 14px;
          color: #4D4D4D;
          margin-bottom: 10px;
        }

        .primary-title {
          display: flex;
          align-items: center;
          font-size: 18px;
          color: #3B3B3B;

          .description {
            font-weight: 400;
            font-size: 14px;
            color: #4D4D4D;
          }
        }
      }
    }

    > .action {
      flex-shrink: 0;
      padding: 10px 20px;
      display: flex;
      align-items: center;

      .num-message {
        color: #4D4D4D;
        font-size: 16px;
        font-weight: 400;
      }

      /deep/ .right-action {
        display: flex;
        margin-left: auto;

        & > .ant-btn {
          margin-right: 26px;
        }

        .form-item {
          display: flex;
          align-items: center;
          margin-bottom: 0;
        }
      }
    }

    > .table {
      flex: 1;
      overflow-y: auto;
      overflow-x: auto;
    }
  }

  .edp-card-head-left-title {
    cursor: pointer;
  }
}
</style>
